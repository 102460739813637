<template>
  <div class="filter-row text-left">
    <CategoricalFilter
      v-if="filter_available('sector_market')"
      title="Sector"
      description="Select the sector or market that is tagged."
      field="sector_market"
      :options="sectors"
      searchable="true"
      mode="tags"
    />
  </div>
</template>

<script>
import CategoricalFilter from "./Filters/CategoricalFilter.vue";
import CountryFilter from './Filters/CountryFilter.vue'

export default {
  name: "StarthubsOptions",
  components: { CategoricalFilter, CountryFilter },
  data: function () {
    return {
      sectors: [
        "Aerospace",
        "Agriculture",
        "Apps",
        "Architecture",
        "Automotive",
        "Blockchains",
        "Business Analytics",
        "Business Development",
        "Charity",
        "Clean Technology",
        "Cleaning",
        "Cloud Data Services",
        "College Campuses",
        "Communications Software",
        "Computers",
        "Construction",
        "Consulting",
        "Consumers",
        "Corporate Training",
        "Cosmetics",
        "Coworking",
        "Creative Industries",
        "Customer Experience",
        "Data",
        "Demographies",
        "Design",
        "Digital Entertainment",
        "Digital Health",
        "Digital Marketing",
        "Digital Publishing",
        "E Learn",
        "Education",
        "Employment",
        "Energy",
        "Entertainment",
        "Environmental Innovation",
        "Events",
        "Fashion",
        "Film",
        "Fin Tech",
        "Food Tech",
        "Furniture",
        "Governments",
        "Graphic Design",
        "Hardware + Software",
        "Health Care",
        "Home & Garden",
        "Hospitality",
        "Human Resources",
        "IT Management",
        "Influencer Marketing",
        "Information Technology",
        "Internet Marketing",
        "Internet of Things",
        "Legal",
        "Life Sciences",
        "Lifestyle",
        "Logistics",
        "Manufacturing",
        "Marketing",
        "Music",
        "Platforms",
        "Politics",
        "Project Management",
        "Public Transportation",
        "Real Estate",
        "Sales",
        "Sharing Economy",
        "Social Media",
        "Sports",
        "Supply Chain Management",
        "Sustainability",
        "Toys",
        "Travel & Tourism",
        "Virtual Reality",
        "Wearables",
        "Websites",
      ],
    };
  },
  methods: {
    filter_available: function(field_name) {
        return this.$store.getters.getCurrentlyAvailableFilters.includes(field_name)
    }
  },
};
</script>

<style scoped>
.btn-secondary {
  padding: 0px 4px;
}
.mw-174 {
  max-width: 174px;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>