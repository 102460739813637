<template>
    <div role="button" class="result-row d-flex flex-column p-4 align-items-start justify-content-between" @click="show.description=1-show.description">
        <div class="h5 mb-2">{{item.fields.display_name}} <small class="ms-2">{{parse_source(item.fields.id)}}</small></div>
        <div class="expert-links">
            <div class="profile-links">
                <!-- <span class="float-start badge bg-warning"><i class="fa fa-bullhorn"></i> {{item.fields. challenge}}</span><br/> -->
                <a v-if="item.fields.url" class="" target="_blank" :href="'https://'+item.fields.url.replace('https://', '')">Website</a>
                <a v-if="item.fields.source_url.slice(0,4) == 'http'" class="" target="_blank" :href="item.fields.source_url">Source</a>
                <a v-if="item.fields.email !== 'False'" class="" target="_blank" :href="'mailto:'+item.fields.email">Mail</a>
            </div>
        </div>
        <div class="mb-2" v-if="!show.description">{{item.fields.description?item.fields.description.slice(0,250):'No description available'}}...</div>
        <div class="mb-2" v-else>
            <div id="startup-details" class="d-flex flex-column justify-content-between mb-1">
                <div v-if="item.fields.foundation_year" class="d-flex flex-row align-items-center"><i class="fa fa-1x fa-calendar me-2"></i><span>Founded in {{item.fields.foundation_year}}</span></div>
                <div v-if="item.fields.city" class="d-flex flex-row align-items-center"><i class="fa fa-1x fa-city me-2"></i><span>{{item.fields.city}} ({{item.fields.country}})</span></div>                  
                <div v-if="item.fields.persons" class="d-flex flex-row flex-wrap align-items-center">
                    <span class="me-2 keyword" :key="item.fields.display_name+'kw'+idx" v-for="(person, idx) in item.fields.persons">
                        <i class="fa fa-1x fa-user me-1"></i>&nbsp;{{person.name}} ({{person['title']}})</span>
                </div>
                <div v-if="item.fields.keywords" class="d-flex flex-row flex-wrap align-items-center">
                    <span class="me-2 keyword" :key="item.fields.display_name+'kw'+idx" v-for="(keyword, idx) in item.fields.keywords"><i class="fa fa-1x fa-tags me-1"></i>&nbsp;{{keyword}}</span>
                </div>
            </div>
            <div v-html="item.fields.description?.replace('_', '<br/>')"></div>
        </div>
        <label-box :item="item" v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('use_collections')"></label-box>
    </div>
</template>

<script>
import LabelBox from '../LabelBox.vue'
export default {
  components: { LabelBox },
    name: "Startup",
    data: function() {return({
        show: {
            description: 0,
        }
    })},
    props: ["item"],
    methods: {
        parse_source(id) {
            let id_part = id.slice(0, 4)
            if (id_part == 'lis-') return 'LinkedIn'
            if (id_part == 'scou') return 'Scouted'
            if (id_part == 'tfi-') return 'Good Food Institute'
            else return 'Brave New Food'
        }
    }
}
</script>

<style scoped>
.badge {
    text-decoration:none;
}
#startup-details svg {
    color:var(--primary_color);
}
.keyword {
    white-space:nowrap;
}
</style>