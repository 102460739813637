<template>
    <div>
        <ResultlistTopbar :sortable="sortable" />
        <Startup :item="startup" v-for="startup in $store.getters.getCurrentResultList" :key="startup.id"/>
    </div>
</template>

<script>
import Startup from './Startup.vue'
import ResultlistTopbar from '../ResultlistTopbar.vue'

export default {
    name: "Startups",
    components: {Startup, ResultlistTopbar},
    data: function() {return({
        sortable: [
            {
                "name" : "Relevance",
                "field": "fields.ranking_score",
                "default_order": "desc"
            },{
                "name" : "Startup name",
                "field": "fields.display_name",
                "default_order": "asc"
            },{
                "name" : "Foundation year",
                "field": "fields.foundation_year",
                "default_order": "desc"
            },
        ]
    })},
}
</script>

<style>

</style>