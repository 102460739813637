<template>
    <div class="slider" role="button" 
        :class="excluded(field)?'exclude':'include'"
        @click="$store.commit('TOGGLE_FILTER_TYPE', {'field': field})">
        <span class="slider-text">Exclude</span>
        <div class="slider-space">
            <span class="slider-toggle"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterSlider',
    props: ['field'],
    methods: {
        excluded: function(field_name) {
            if (this.$store.getters.getFilterByField(field_name)) {
                return this.$store.getters.getFilterByField(field_name)['type'] === 'exclude'
            }
            else return false
        },
    }
}
</script>

<style>
 .slider {
        align-items:center;
        display:flex;
        justify-content:flex-end;
        margin-top:.25rem;
    }
    .slider-space{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        border-radius: 18px;
        width:25px;
        height:12px;
        float:right;
    }
    .slider-toggle {
        display:block;
        margin-top:1px;
        width:10px;
        height:10px;
        border-radius:10px;
        transition:all ease var(--fast_anim)
    }
    .slider.exclude .slider-toggle {
        margin-left:14px;
        background: var(--primary_color);
    }
    .slider.include .slider-toggle {
        margin-left:1px;
        background:white;
    }
    .slider-text {
        margin-right:.25rem;
        font-size: 83.33%;
    }
    .slider.exclude .slider-text {
        color:var(--primary_color);
    }
</style>