<template>
    <div>
        <ResultlistTopbar :sortable="sortable"/>
        <div class="d-flex flex-column w-100" v-for="item in $store.getters.getCurrentResultList" :key="item.fields.id">
            <div class="d-flex flex-column result-row p-4 mb-2">
                <div class="d-flex flex-grow-1 journal-title">
                    <div class="journal-name me-4">
                        {{item.fields.display_name}} 
                        <span v-if="item.fields.is_oa" class="ms-2 oa-logo open" title="Open Access"></span>
                        <span v-if="!item.fields.is_oa" class="ms-2 oa-logo closed" title="Closed Access"></span>
                    </div>
                    <div class="journal-affiliation">{{item.fields.host_organization_name}} {{item.fields.country_code?'('+item.fields.country_code+')':''}}</div>
                </div>
                <div class="expert-links">
                    <div class="profile-links">
                        <a :href="item.fields.homepage_url" target="_blank">Website</a>
                        <GreyDivider margin_class="mx-2"/>
                        <span>Citations {{item.fields.cited_by_count}}</span>
                        <GreyDivider margin_class="mx-2"/>
                        <span v-if="item.fields.summary_stats">Impact Factor {{Math.round(100*item.fields.summary_stats['2yr_mean_citedness']??0)/100}}</span>
                    </div>
                </div>
                <CollectionItems :item="item"  :pubs="item.collection.records"/>
                <div>
                    <label-box v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('use_collections')" :item="item"></label-box> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CollectionItems from '../CollectionItems.vue'
import GreyDivider from '../GreyDivider.vue'
import LabelBox from '../LabelBox.vue'
import ResultlistTopbar from '../ResultlistTopbar.vue'

export default {
    name: "Venues",
    components: {LabelBox, ResultlistTopbar, CollectionItems, GreyDivider},
    data: function() {return({
        sortable: [
            {
                "name" : "Relevance",
                "field": "fields.ranking_score",
                "default_order": "desc"
            },{
                "name" : "Journal Name",
                "field": "fields.display_name",
                "default_order": "desc"
            },{
                "name" : "Impact Factor",
                "field": "fields.summary_stats.2yr_mean_citedness",
                "default_order": "desc"
            },{
                "name" : "Citation count",
                "field": "fields.cited_by_count",
                "default_order": "desc"
            },
        ]
    })},
    methods: {
        get_query_string: function(policy_doc) {
            return encodeURIComponent(policy_doc.fields.title + " " + policy_doc.fields.id.split('-')[0])
        },
    }
}
</script>

<style scoped>
.journal-title {
    max-width:800px;
}
.journal-affiliation {
    color: var(--almost-black);
    font-size: 1.2rem;
    font-weight: 500;
}
.journal-name {
    display: flex;
    align-items: baseline;
    font-size: 1.2rem;
    font-weight: 900;
    color: var(--almost-black);
}

.oa-logo {
    height:1.2rem;
    width:1.2rem;
}
.oa-logo.open {
    background-color: var(--orange_highlight);
    -webkit-mask: url('../../assets/img/icons/open_access.svg') no-repeat center;
    -webkit-mask-size:contain;
}
.oa-logo.closed {
    background-color: var(--primary_color);
    -webkit-mask: url('../../assets/img/icons/closed_access.svg') no-repeat center;
    -webkit-mask-size:contain;
}
.no-decoration {
    color:var(--secondary_color);
    text-decoration:none;
}
.no-decoration:hover {
    text-decoration:underline;
}
</style>