<template>
    <div v-if="author?.identifier" class="me-1 author_badge"
        @mouseenter="hovering_author = author.identifier"
        @mouseleave="hovering_author = false"
        role="button"
        :class="{'active': author.identifier == hovering_author}"
    >
    {{author.fields.display_name}}
    </div>
</template>

<script>
export default {
    name: "SmallAuthorBadge",
    props: ["author"],
    computed: {
        hovering_author: {
            get() {
                return this.$store.state.coi_author_hover
            },
            set(value) {
                this.$store.state.coi_author_hover = value
            }
        }
    }
}
</script>

<style scoped>
.author_badge {
    display:flex;
    flex-direction:column;
    text-align:left;
    position:sticky;
} 
.author_badge {
    border-radius: 5px;
    text-decoration: none;
    padding: 0 .5rem;
    margin: .25rem .25rem .25rem 0;
    background: white;
    border: 1px solid var(--primary_color);
    color:var(--primary_color);
    font-size: .8rem;
    font-weight:400;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
    text-align:center;
}
.author_badge:hover, .author_badge.active {
    background:var(--primary_color);
    color:white;
}

</style>