import {createRouter, createWebHistory, RouterView} from 'vue-router'
import Home from '../views/Home.vue'
import Upgrade from '../views/Upgrade.vue'
import FilterPresets from '../views/FilterPresets.vue'
import CoiChecker from '../views/CoiChecker.vue'
import APIDocs from '../views/APIDocs.vue'


  const routes = [
  {
    path: '/app/',
    name: 'Home',
    component: Home
  },
  {
    path: '/app/query/:query/',
    name: 'QueryReplay',
    component: Home
  },
  {
    path: '/app/collection/:collection/',
    name: 'ContinueCollection',
    component: Home
  },
  {
    path: '/app/filters/',
    name: 'Filters',
    component: FilterPresets
  },
  {
    path: '/app/coi_checker/',
    name: 'Conflict of Interest Checker',
    component: CoiChecker
  },
  {
    path: '/app/upgrade/',
    name: 'Upgrade',
    component: Upgrade
  },
  {
    path: '/api/docs/',
    name: 'API Documentation',
    component: APIDocs
  },
  {
    path: '/app/collections',
    name: 'Collections',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Collections.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  //base: process.env.BASE_URL,
  routes
})

export default router
