<template>
    <div>
        <ResultlistTopbar :sortable="sortable"/>
        <div class="result-row" v-for="item in $store.getters.getCurrentResultList" :key="item.id">
            <Institution :item="item" :collection="item.collection.records"/>            
        </div>
    </div>
</template>

<script>
import Institution from './Institution.vue'
import ResultlistTopbar from '../ResultlistTopbar.vue'

export default {
    name: "Institutions",
    components: {Institution, ResultlistTopbar},
    data: function() {return({
        sortable: [
            {
                "name" : "Relevance",
                "field": "fields.ranking_score",
                "default_order": "desc"
            },{
                "name" : "Work count",
                "field": "fields.works_count",
                "default_order": "desc"
            },{
                "name" : "Institution name",
                "field": "fields.display_name",
                "default_order": "asc"
            },
        ]
    })},
}
</script>

<style>

</style>