<template>
    <div>
        <ResultlistTopbar :sortable="sortable"/>
        <div class="d-flex" v-for="item in $store.getters.getCurrentResultList" :key="item.fields.id">
            <div class="d-flex result-row flex-column p-4 justify-content-between w-100 align-items-start">
                <div class="d-flex">
                    <div class="expert-name me-2">{{item.fields.name}}</div>
                    <div class="expert-affiliation">{{item.fields.city}} {{item.fields.country?''+item.fields.country+'':''}}</div>
                </div>
                <CollectionItems  :item="item" :pubs="item.collection.records"></CollectionItems>
            </div>
        </div>
    </div>
</template>

<script>
import LabelBox from '../LabelBox.vue'
import ResultlistTopbar from '../ResultlistTopbar.vue'
import CollectionItems from '../CollectionItems.vue'
export default {
    name: "Partners",
    components: {LabelBox, ResultlistTopbar, CollectionItems},
    data: function() {return({
        sortable: [
            {
                "name" : "Relevance",
                "field": "fields.ranking_score",
                "default_order": "desc"
            },{
                "name" : "Institution Name",
                "field": "fields.name",
                "default_order": "desc"
            // },{
            //     "name" : "Project count",
            //     "field": "fields.proj_count",
            //     "default_order": "desc"
            },
        ]
    })},
    methods: {
        get_query_string: function(policy_doc) {
            return encodeURIComponent(policy_doc.fields.title + " " + policy_doc.fields.id.split('-')[0])
        },
        fix_swecris: function(url) {
            if (!url) {return url};
            url = url.replace('https://www.swecris.se/betasearch/details/', 'https://www.vr.se/english/swecris.html#/')
            const regex = /project\/[0-9]*-([0-9]*)([a-zA-Z])/gm;
            const subst = `$1_$2`;
            url = url.replace(regex, subst);
            return url
        }
    }
}
</script>

<style scoped>
.no-decoration {
    color:initial;
    text-decoration:none;
}
.no-decoration:hover {
    text-decoration:underline;
}
</style>