<template>
  <div v-show="$store.state.show['modal']">
    <div class="modal_backdrop" @click="$store.commit('CLOSE_MODAL')"></div>
    <div id="modal">
        <div v-if="show.modal_loading" class="modal-loader">
          <Spinner></Spinner>
        </div>
      <Component v-else :key="'modal_'+$store.getters.getActiveModal?.type" :modal="$store.getters.getActiveModal" :is="$store.getters.getActiveModal?.type"/>
    </div>
  </div>
</template>

<script>
import CoAuthorModal from "./CoAuthor.vue"
import CoIDetails from "./CoIDetails.vue"
import Topics from "./Topics.vue"
import Spinner from "../Spinner.vue"
import { mapState } from "vuex";
import _ from "lodash"

export default {
    name: 'ModalContainer',
    components: {CoAuthorModal, Spinner, Topics, CoIDetails},
    computed: {
      ...mapState(['show']),
  }
}
</script>

<style>
.modal_backdrop {
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    position: fixed;
    z-index:30;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px)
}
#modal {
    position:fixed;
    background-color:white;
    border-radius: var(--ms-radius);
    box-shadow:var(--box_shadow_emphasis);
    z-index:31;
    top:10vh;
    left:10vw;
    width:80vw;
    max-height:80vh;
}
</style>