<template>
      <div class="mb-3">
        <div class="sidebar-title" title="Select whether Global Campus has a publicly known email address.">Email Address</div>
        <Multiselect
            mode="single"
            valueProp="id"
            :searchable="false"
            placeholder="Filter by email address" 
            v-model="selected_value"
            :object="false"
            :id="field+'_multiselect'"
            :closeOnSelect="true"
            :closeOnDeselect="true"
            :canClear="true"
            :options="options"
            :class="{'active' : selected_value !== null}">
            <template v-slot:option="{ option }">
              <span>{{ option.label }}</span>
            </template>
        </Multiselect>
    </div>
</template>

<script>
// This component is specifically here to handle categorical
// to range conversaion for this filter.
// Bear in mind, the scale for similarity is 0-100 (so not 0-1!)
import Multiselect from '@vueform/multiselect'

export default {
    name: 'EmailConfidenceFilter',
    components: {Multiselect},
    computed: {
        selected_value: {
            get() {
                return this.range_to_option(this.$store.state.filters[this.field])
            },
            set(option) {
                if (option == null) {
                    this.$store.commit('CLEAR_FILTER', {field: this.field})
                }
                else {
                    this.$store.commit('SET_FILTER', this.option_to_range(option))
                }
            }
        }
    },
    data: function() {return{
        field: 'email_confidence',
        options: [
            {id: 'similar_email', label: 'Similar to name'},
            {id: 'any_email', label: 'Any email'},
        ]
    }},
    methods: {
        range_to_option(range) {
            if (range && range.start == 75) return 'similar_email'
            if (range && range.start == 0) return 'any_email'
            return null
        },
        option_to_range(option) {
            const filter_obj = {
                'field': 'email_confidence',
                'type': 'range',
                'end': null
            }
            if (option == 'similar_email') {
                filter_obj['start'] = 75
            }
            if (option == 'any_email') {
                filter_obj['start'] = 0
            }
            return filter_obj
        },

    },
}
</script>

<style>

</style>