<template>
    <div id="tooltip" role="tooltip" v-show="selected_item || $store.state.show.tooltip_message">
        <div data-popper-arrow id="arrow"></div>
        <div class="popup-details">
            <CloseButton @close="closeTooltip"/>
                <div v-show="$store.state.show.tooltip_message">{{$store.state.show.tooltip_message}}</div>
                <div v-show="!$store.state.show.tooltip_message" class="popup-table">
                    <Expert v-if="selected_item.record_type == 'author'" :in_popup="true" :item="selected_item"></Expert>
                    <Work v-if="selected_item.record_type == 'work'" default_open="true" :in_popup="true" :work="selected_item"></Work>
                </div>
        </div>
    </div>
</template>

<script>
import Expert from '../Expert/Expert.vue'
import Work from '../Work/Work.vue'
import CloseButton from '../Utils/CloseButton.vue'
import {mapState} from 'vuex'

export default {
    name: 'PopupContainer',
    components: {Expert, Work, CloseButton},
    computed: {...mapState(['selected_item'])},
    methods:{
        closeTooltip: function() {
            this.$store.commit('SET_TOOLTIPPED_ITEM', false)
        },
    }
}
</script>

<style>
#tooltip {
    z-index:10;
}
</style>