<template>
    <div id="TopicModal">
        <div class="modal-title align-items-end">
            <div class="d-flex justify-content-center align-items-end col-md-6 col-10 ps-3 flex-wrap">
                <div class="h3 mb-2">{{modal.data.query_author.fields.display_name}}</div>
                <small class="ms-2 mb-2">{{lki?.display_name}}</small>
            </div>
            <div id="close_topic_modal" role="button" class="mb-2 col-2" @click="$store.commit('CLOSE_MODAL')">
                <i class="fa fa-close fa-2x me-2 float-end"></i>
            </div>
        </div>
        <hr class="my-1"/>
        <div class="legend_and_chart flex-md-row flex-column">
            <VChart ref="sunburst_chart" id="sunburst-chart" class="chart col-md-6 col-12" :option="chart_options" @mouseover="mousing_over_chart" />
            <div class="topic-legend-container col-md-6 col-12">
                <h4>Top topics</h4>
                <span>The following topics were assigned to the works of {{modal.data.query_author.fields.display_name}}.</span>
                <ul>
                    <li class="topic-legend-item" 
                        :class="{hovering: hovering_over_topics.includes(topic.id)}"
                        :style="'color:'+topic.itemStyle.color"
                        :id="topic.id+'_legend'"
                        :key="'topic'+idx" v-for="(topic, idx) in topic_legend_sorted.slice(0, (25 - topics_limited * 20))" 
                        @mouseleave="unhover_all()" 
                        @mouseenter="hover_topic_legend(topic)">
                        <div class="topic-legend-row">
                            <span class="topic-legend-title">
                                <i class="fa fa-square me-2"></i>
                                <span class="crop-text">{{topic.name}}</span>
                            </span>
                            <span class="mx-2">{{topic.value}}</span>
                        </div>
                    </li>
                </ul>
                <div @click="topics_limited = 1 - topics_limited">
                    <span class="expert-action" role="button" v-show="topics_limited">+ Show more</span>
                    <span class="expert-action" role="button" v-show="!topics_limited">- Show less</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { use } from "echarts/core";
import VChart from "vue-echarts";
import {LabelLayout} from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { SunburstChart } from "echarts/charts";
import {TooltipComponent} from "echarts/components";
import shared from "../shared.js"

use([
  CanvasRenderer,
  SunburstChart,
  TooltipComponent,
  LabelLayout,
]);


export default {
    name:"topics",
    props: ["modal"],
    components: {VChart},
    computed: {
        lki: function() {
            return shared.get_lki(this.modal.data.query_author)
        },
        topic_legend_sorted: function() {
            let _this = this;
            return _.orderBy(_this.topic_legend, 'value', 'desc')
        },
        chart_options: function() {
            let _this = this;
            // The Topic map comprises of the following levels:
            // Topic <-- Subfield <-- Field <-- Domain (root)
            // these are initially all dicts to facilitate lookup.
            let domains = {}
            let fields = {}
            let subfields = {}
            // loop over the groups in the response (topic counts)
            let groups = this.modal.data.topic_data.group_by
            _.forEach(groups, function(group) {
                var topic = {
                    "id": group['key'].replace('https://openalex.org/', ''),
                    "name": group['key_display_name'],
                    "level": "topic",
                    "value": group['count'],
                    "label": {
                        "color": "transparent",
                    }
                }
                var subfield_id = shared.topics[topic['id']]["sf.id"]
                if(subfields[subfield_id]) {
                    subfields[subfield_id].children.push(topic)
                    subfields[subfield_id].value += topic.value
                }
                else {
                    subfields[subfield_id] = {
                        'id': subfield_id,
                        'name': shared.subfields[subfield_id]['sf.dn'],
                        "level": "subfield",
                        'children': [topic],
                        'value': topic.value,
                        "label": {
                            "color": "white",
                        }
                    }
                }
            })
            // loop over subfields to create fields layer
            _.forEach(subfields, function(subfield) {
                var field_id = shared.subfields[subfield.id]["f.id"]
                if (fields[field_id]) {
                    fields[field_id].children.push(subfield)
                    fields[field_id].value += subfield.value
                }
                else {
                    fields[field_id] = {
                        'id': field_id,
                        'name': shared.fields[field_id]['f.dn'],
                        "level": "field",
                        'children': [subfield],
                        'value': subfield.value,
                        "label": {
                            "color": "white",
                        }
                    }
                }
            })
            // loop over fields to create domains layer
            _.forEach(fields, function(field) {
                var domain_id = shared.fields[field.id]["d.id"]
                if (domains[domain_id]) {
                    domains[domain_id].children.push(field)
                    domains[domain_id].value += field.value
                }
                else {
                    domains[domain_id] = {
                        'id': domain_id,
                        'name': shared.fields[field.id]["d.dn"],
                        "level": 'domain',
                        'children': [field],
                        'value': field.value,
                        'itemStyle': {
                            'color': shared.domain_colors[domain_id]['bg']
                        },
                        'label': {
                            'color': "white", 
                            'stroke': 'none'
                        }
                    }
                }
            })
            function propagate_style_to_children(parent) {
                if (parent.children) {
                    parent.children.forEach(child => {
                        child.itemStyle = parent.itemStyle
                        // child.label = parent.label
                        if (child.children) {
                            child = propagate_style_to_children(child)
                        }
                        // after we've propagated styles to the lowest level (topic)
                        // we add those topics to the legend for styling.
                        if (child && child.level == 'topic') {
                            _this.topic_legend.push(child)
                        }
                    })
                }
            }
            _.forEach(domains, function(domain) {
                domain = propagate_style_to_children(domain)
            }) 
            return {
                tooltip: {
                    show:true,
                },
                series: {
                    labelLayout(params) {
                        return {
                            width: Math.min(.7*params.rect.width, 200),
                            hideOverlap:true,
                            height: params.rect.height,
                            fontSize: Math.min(Math.sqrt(params.rect.width), 20)
                        };
                    },
                    label: {
                        show: true,
                        verticalAlign:'center',
                        minAngle: 15,
                    },
                    type: 'sunburst',
                    emphasis: {
                        focus: "ancestor",
                        label: {

                        },
                    },
                    data: Object.values(domains),
                    levels: [
                        {
                            label: {
                                rotate: 'tangential',
                                show: true,
                                fontSize:16,
                                formatter: function() {              
                                    return 'Go up'
                            },
                            }
                        },
                        {
                            r0: "10%",
                            r: "25%",
                            label: {
                                rotate: 'tangential',
                            }
                        },
                        {
                            r0: "25%",
                            r: "50%",
                            label: {
                                overflow: 'break',
                                rotate: 'tangential',
                            },
                        },
                        {
                            r0: "50%",
                            r: "75%",
                            label: {
                                overflow: 'break',
                                rotate: 'tangential',
                            },
                        },
                        {
                            r0: "78%",
                            r: "85%",
                            label:{
                                show:false,
                                formatter: '',
                        }}
                    ]

                }
            }
        },
    },
    data: function() {return({
        // theme macarons https://echarts.apache.org/en/theme-builder.html
        topic_legend: [],
        topics_limited:1,
        hovering_over_topics: [],
        resize_sunburst_observer: {},
        eChart: {},
    })},
    methods: {
        hover_topic_legend: function(topic) {
            this.hovering_over_topics = [topic.id]
            this.$refs.sunburst_chart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                name: topic.name,
            });
            this.$refs.sunburst_chart.dispatchAction({
                type: 'showTip',
                seriesIndex: 0,
                name: topic.name,
                });
        },
        unhover_all: function() {
            this.$refs.sunburst_chart.highlightValues = null;
            this.$refs.sunburst_chart.dispatchAction({
                type: 'showTip',
                seriesIndex: 0,
            });
            this.$refs.sunburst_chart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
            });
            this.hovering_over_topics = []
        },
        hover_over_self_and_descendants: function(node) {
            if (node.level == 'topic') {
                this.hovering_over_topics.push(node.id)
            }
            else if (node.children) {
                node.children.forEach((child) =>
                    this.hover_over_self_and_descendants(child)
                )
            }
            // if you're only hovering on one, make sure it is in view.
            if (this.hovering_over_topics.length == 1) {
                let legend_item = document.getElementById(this.hovering_over_topics[0]+'_legend')
                if (legend_item) {
                    legend_item.scrollIntoView({behavior: 'smooth'})
                }
            }
        },
        mousing_over_chart: function(event) {
            this.hovering_over_topics = []
            this.hover_over_self_and_descendants(event.data)
        },
    },
    mounted: function() {
        let _this = this;
        let topic_modal_elem = document.getElementById('TopicModal')
        this.resize_sunburst_observer = new ResizeObserver(function() {
            if (_this.$refs.sunburst_chart) {
                _this.$refs.sunburst_chart.resize()
            }
        })
        this.resize_sunburst_observer.observe(topic_modal_elem)
    },
    onBeforeUnmount:function() {
        const topic_modal_elem = document.getElementById('TopicModal')
        this.resize_sunburst_observer.unobserve(topic_modal_elem)
    }
}

</script>

<style>
#TopicModal {
    display: flex;
    flex-direction:column;
    width:80vw;
    height:80vh;
}
#TopicModal ul {
    padding-left:0;
    margin-bottom:.25rem;
}
#TopicModal ul li {
    list-style-type:none;
}
#TopicModal .modal-title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding:1rem 1rem 0 0rem;
}
.legend_and_chart {
    display:flex;
    flex-grow:1;
    align-items:center;
}
@media (min-width: 992px) {
    .chart {
        position:sticky;
        top:0;
    }
}

.topic-legend-container {
    display:flex;
    flex-direction:column;
    padding: 0 .5rem;
    max-height:calc(80vh - 90px);
    overflow-y:auto;
    overflow-x:hidden;
}
.topic-legend-row {
    white-space:nowrap;
    overflow-x:clip;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
}
.topic-legend-item {
    max-height: 1.4rem;
}
.topic-legend-title i, .topic-legend-title svg {
    color:inherit;
}
.topic-legend-title span {
    color:var(--almost_black);
}
#TopicModal ul li.hovering span {
    color: inherit;
    cursor: default;
    font-weight:700;
}
.expert-action:hover {
    color:var(--orange_highlight);
}
</style>