<template>
    <div class="d-flex flex-column">
        <ResultlistTopbar :sortable="sortable"/>
        <div class="result-row px-2 py-4" v-for="project in $store.getters.getCurrentResultList" :key="project.identifier">
            <Project :item="project"/>
        </div>
    </div>
</template>

<script>
import Project from './Project.vue'
import ResultlistTopbar from '../ResultlistTopbar.vue'

export default {
    name: "Projects",
    components: {Project, ResultlistTopbar},
    data: function() {return({
        sortable: [
            {
                "name" : "Relevance",
                "field": "fields.ranking_score",
                "default_order": "desc"
            },{
                "name" : "Start Date",
                "field": "fields.start_date",
                "default_order": "desc"
            },{
                "name" : "Name",
                "field": "fields.display_name",
                "default_order": "asc"
            },
        ]
    })},
}
</script>

<style>

</style>