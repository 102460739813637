<template>
<div id="spinner-container">
  <div id="spinner" class="text-center mt-5">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>
</template>

<script>

export default {
    name: "spinner",
    methods: {
    }
}   
</script>

<style>
#spinner-container{
  display:flex;
  height:60vh;
}
#spinner {
  flex-grow:1;
  align-self:center;
}
</style>