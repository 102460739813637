<template>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <a class="navbar-brand" href="/app/"></a>
            <div class="dropdown">
                <a class="avatar-link dropdown-toggle d-flex align-items-center" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="user-avatar d-flex align-items-center justify-content-around">
                        <i class="fa fa-user"></i>
                    </div>
                    <span class="me-1"><strong>{{user_profile.user.first_name}}</strong> - {{user_profile.organisation.name}}</span>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" v-if="user_profile.organisation.name !== 'AcademicTransfer'" href="/app/collections/"><i class="fa fa-layer-group me-1"></i> My Collections</a>
                <a class="dropdown-item" v-if="user_profile.organisation.name == 'AcademicTransfer'" href="/app/upgrade/"><i class="fa fa-rocket me-1"></i> Upgrade license</a>
                <a class="dropdown-item" href="/app/filters/"><i class="fa fa-filter me-1"></i> My Filters</a>
                <a class="dropdown-item" href="/app/coi_checker/" v-if='["Global Campus", "KWF"].includes(user_profile.organisation.name)'><i class="fa fa-arrows-left-right-to-line me-1"></i> Conflicts of Interest</a>
                <a class="dropdown-item" href="/logout/"><i class="fa fa-sign-out me-1"></i> Sign Out</a>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: "Navbar",
    computed: mapState([
    // map this.count to store.state.count 
        'searchmodules', 'selected_searchmodule', 'user_profile'
    ]),
    data: function() {return({
    })},
    methods: {
        check_active_source: function(searchmodule) {
            if (typeof(this.selected_searchmodule) !== 'undefined' && this.selected_searchmodule.pk === searchmodule.pk) {
                return 'searchmodule-active'
            }
        }
    }
}   
</script>

<style scoped>
    nav{
        color:white;
        background-color: var(--secondary_color);
        z-index:10;
    }
    .container-fluid {
        position:sticky;
        max-width:1920px;
        width:100vw;
    }
    .navbar-brand {
        mask: url('../assets/img/gc_logo.svg');
        -webkit-mask: url('../assets/img/gc_logo.svg');
        mask-size: fit;
        -webkit-mask-size: fit;
        mask-repeat:no-repeat;
        -webkit-mask-repeat:no-repeat;
        background-color:#f7f7f7;
        width:160px;
        height:30px;
    }
    .navbar-brand:hover {
        background-color:var(--orange_highlight)!important;
    }
    .searchmodule-active {
        color:var(--orange_highlight);
    }
    .user-avatar {
        width:30px;
        height:30px;
        margin-right:.5rem;
        display:inline-block;
        border-radius:50%;
        background: grey;
        color:white;
    }
    .avatar-link {
        text-decoration: none;
        color:white;
    }
    .avatar-link:hover {
        color:var(--orange_highlight);
    }
</style>