<template>
    <div class="d-flex flex-column justify-content-between">
        <span class="source-cell">
            <span class="me-1">Start date: {{new Date(item.fields.start_date*1000).toLocaleDateString()}}</span>
        </span>
        <div @click="show.description=1-show.description" role="button">
            <h5 class="pt-2">{{item.fields.title}}</h5>
            <div v-if="item.fields.abstract">
                <p v-if="show.description" v-html="item.fields.abstract"></p>
                <p v-else>{{item.fields.abstract.slice(0, 100)}}...</p>
            </div>
        </div>
        <div class="d-flex">
            <label-box v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('use_collections')" :item="item"></label-box>
            <a class="btn-primary btn-small" target="_blank" :href="item.fields.url"> Find at {{authority_ids[item.fields.authority_id]}}</a>
        </div>
    </div>
</template>

<script>
import LabelBox from '../LabelBox.vue'
export default {
  components: { LabelBox },
    name: "Project",
    props: ["item"],
    data: function() {return({
        show: {
            description: 0,
        },
        authority_ids: {
            48:'Cordis (EU)',
            56:'FRIS (Brussel, BE)',
            57:'FRIS (ILVO, BE)',
            58:'FRIS (UGENT, BE)',
            61:'FRIS (UHASSELT, BE)',
            62:'FRIS (UANTWERPEN, BE)',
            63:'FRIS (KULEUVEN, BE)',
            64:'FRIS (INBO, BE)',
            65:'FRIS (ITG, BE)',
            66:'GEPRIS (DE)',
            49:'NARCIS (NL)',
            55:'NSF (US)',
            59:'SWECRIS (SE)',
            60:'UKRI (UK)',
        }
    })}
}
</script>

<style>

</style>