<template>
  <div class="text-grey me-2">
    <span v-if="result_count <= nBelowThreshold"
      >Showing top {{ result_count }} of {{ nBelowThreshold>999?'1000+':nBelowThreshold}}</span
    >
    <span v-else class=""
      >Showing best {{ result_count }}
      <i
        class="fa fa-warning text-danger"
        title="The best matches were less similar than usual.
This could indicate a difficult query, spelling mistakes or strict filters.
We still show the closest results."
      ></i
    ></span>
  </div>
</template>

<script>
export default {
  name: "SearchStats",
  computed: {
    result_count() {
      return this.$store.getters.getCurrentResultList?.length;
    },
    nBelowThreshold() {
      return this.$store.getters.getCurrentResultStats.distance_stats?.n_below_threshold
    },
  },
};
</script>

<style>

</style>