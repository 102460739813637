<template>
  <div class="home" :style="getColorStyling()">
    <navbar></navbar>
    <div id="app-content" class="d-flex flex-column flex-grow-1">
      <div class="px-2" id="collections-index">
        <h2 class="py-4">Unlock the potential of GlobalCampus</h2>
        <h4 class="py-2">Extra datasets</h4>
        <p>A full license grants access to unique datasets, such as matching with 500.000 previously funded projects. The following extra options are available:</p>
        <ul>
          <li>Find similar research projects</li>
          <li>Find specialized Partners and Institutions for your research consortium</li>
          <li>Identify related national Policy Goals</li>
          <li>Get suggestions for journals</li>
          <li>Or.. bring your own dataset to match with!</li>
        </ul>
        <h4 class="py-2">Extra features</h4>
        <p>A full license provides detailed search options.</p>
        <ul>
          <li>Use <strong>advanced filters</strong> to find experts from Industry, exclude certain countries or focus on Last or First authors.</li>
          <li>Look at <strong>collaboration networks</strong></li>
          <li>Search <strong>interactively</strong>: Tell the algorithm what you like, and steer towards more relevant results.</li>
          <li>Share with colleagues <span class="badge">Institution license</span></li>
          <li><strong>API</strong> Access: Integrate GlobalCampus search queries in other API's, or even public facing websites. <span class="badge">Institution license</span></li>
        </ul>
        <h4 class="py-2">Interested?</h4>
        <p>Licenses are available for individuals, but also for your entire organisation. Above 15 users, institution access becomes more cost-effective than individual licenses.</p>
        <p>If you want to know more, e-mail Paul Tuinenburg for a trial at <a href="mailto:paul@globalcampus.ai">Paul@GlobalCampus.ai</a></p>
      </div>
    </div>
  </div>
</template>

<script>
//dependencies
import navbar from '../components/Navbar.vue'
import shared from '../components/shared'
import {mapState} from 'vuex'

export default {
  name: 'Upgrade',
  components: {navbar},
  computed: {
    ...mapState(['user_profile']),
    colorMap: shared.colorMap
  },
  created: function() {
    this.$store.commit('SET_USER',JSON.parse(document.getElementById('user-data').text));
  },
  data: function() {return(
    {user: {
      stored_collections: []
    }
    })},
  methods: {
      get_icon: function(icon) {
        return shared.result_type_to_icon(icon)
      },
      getCookie: function(name) {
          let cookieValue = null;
          if (document.cookie && document.cookie !== '') {
              const cookies = document.cookie.split(';');
              for (let i = 0; i < cookies.length; i++) {
                  const cookie = cookies[i].trim();
                  // Does this cookie string begin with the name we want?
                  if (cookie.substring(0, name.length + 1) === (name + '=')) {
                      cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                      break;
                  }
              }
          }
          return cookieValue;
      },
      getColorStyling: function() {
        return shared.colorMap(this.user_profile.organisation.name)
      }
    },
}
</script>

<style scoped>
   .home {
    height: 100vh;
    background-color:var(--bakcground-color);
    width:100vw;
    overflow-y: hidden;
  }
  .no-decoration {
    text-decoration:none;
  }
  .query-link {
    color:initial;
  }
  .hover-orange:hover  {
    color:var(--orange_highlight);
  }
  #app-content{
      display:flex;
      width:100vw;
      min-width:800px;
      margin:auto;
      height:calc(100vh - 40px);
      overflow-y:auto;
  }
  #collections-index {
    margin: 0 auto;
    max-width: 1920px;
    width: 100vw;
  }
</style>