<template>
    <div class="query-row">
      <Expert 
        :key="'exp_'+identifier" 
        v-if="display_name" 
        :item="most_complete_item" 
        :max_paper_count="Math.max(max_paper_count, most_complete_item.fields.works_count, 0)"
      />
    </div>
</template>

<script>
// This a wrapper for an Expert in a query - to ensure it is at the top of the result list
// and is enriched through a frontend API call.
import Expert from "./Expert";

export default {
  name: "QueryExpert",
  props: ["identifier", "display_name", "max_paper_count"],
  components: {Expert},
  computed: {
    most_complete_item: function() {
      // The query author is potentially in the result list, and then we have more data and a subcollection.
      if(this.$store.getters.getCurrentResultListByID[this.identifier]) {
        return this.$store.getters.getCurrentResultListByID[this.identifier]
      }
      // Alternatively, we can have enriched data by the enrich call in the mount function of this component
      else {
        return this.$store.state.searchbox_collection.records[0]
      }
    },
  },
  data: function () {return {
    enriched_item: {
        identifier: this.identifier,
        fields: {
          display_name: this.display_name,
          id: this.identifier.replace("https://openalex.org/", '')
        }
    }
  }},
  mounted: function () {
    let _this = this;
    this.$axiosQ.jumpQueue('openalex', {
      method: 'get', 
      url: "https://api.openalex.org/authors?filter=openalex_id:"+this.identifier})
      .then(function(response) {
        _this.$store.commit('ENRICH_SEARCHBOX_RECORD', response.data.results[0])
    })
  },
  methods: {
  }
}
</script>

<style>
.query-row {
  background-color: var(--primary_color_bg);
}
</style>