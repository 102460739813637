<template>
    <div id="search-module-selector" class="popup-details">
        <div class="float-end close-button close-button-work" @click="$emit('close')"><i class="fa fa-close"></i></div>
        <div class="mb-2">Select the dataset for which you would like a filter preset:</div>
        <button
            @click="$emit('type_selected', searchmodule)"
            class="btn-primary px-4 mb-2 me-2 py-2 small"
            :key="searchmodule.pk"
            role="button"
            v-for="searchmodule in searchmodules"
        >
        {{ searchmodule.display_name }}
        </button>
    </div>
</template>

<script>
import { mapState } from "vuex";
import shared from "./shared";
import { createPopper } from '@popperjs/core';

export default {
    name: 'SearchModuleSelector',
    computed: {
        ...mapState(["selected_searchmodule", "searchmodules"]),
    },
    methods: {
        get_icon: function (icon) {
        return shared.result_type_to_icon(icon);
        },
    },
    mounted: function() {
        const anchor = document.querySelector('#add_preset');
        const tooltip = document.querySelector('#search-module-selector');
        createPopper(anchor, tooltip, {
            placement: 'right',
            modifiers: [
              {
                  name: 'offset',
                  options: {
                      offset: [16, 16],
                  },
              },
              {
                name: 'arrow',
                options: {
                    padding: 16, // 16px from the edges of the popper
                },
              },
          ],
        });
    }
}

</script>

<style>
#search-module-selector {
    background:white;
    max-width:20vw;
    padding:1rem;   
}
</style>