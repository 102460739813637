<template>
  <div class="mb-3">
    <div class="sidebar-title" title="Filter by the types of document.">
      Document type
    </div>
    <Multiselect
      mode="tags"
      label="label"
      valueProp="id"
      :searchable="false"
      placeholder="Select a document type"
      v-model="document_type"
      :object="false"
      id="document_type_multiselect"
      :closeOnSelect="true"
      :closeOnDeselect="true"
      :options="available_document_types"
      :class="{ active: document_type !== 'Any' && document_type !== null }"
    >
      <template v-slot:option="{ option }">
        <span class="capitalize">{{ option.label }}</span>
      </template>
      <template v-slot:singlelabel="{ value }">
        <div class="multiselect-single-label capitalize">{{ value.name }}</div>
      </template>
    </Multiselect>
    <FilterSlider field="document_type" />
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import FilterSlider from "./FilterSlider.vue";

export default {
  name: "DocumentTypeFilter",
  components: { Multiselect, FilterSlider },
  computed: {
    document_type: {
      get: function () {
        let value = this.$store.state.filters["document_type"]?.values;
        if (!value || value == []) {
          return [];
        } else {
          return value;
        }
      },
      set: function (value) {
        this.$store.commit("SET_FILTER", {
          field: "document_type",
          values: value,
          type: "include",
        });
      },
    },
  },
  data: function () {
    return {
      available_document_types: [
        {
          id: "book-section",
          label: "Book Section",
        },
        {
          id: "monograph",
          label: "Monograph",
        },
        {
          id: "report-component",
          label: "Report Component",
        },
        {
          id: "report",
          label: "Report",
        },
        {
          id: "peer-review",
          label: "Peer Review",
        },
        {
          id: "book-track",
          label: "Book Track",
        },
        {
          id: "journal-article",
          label: "Journal Article",
        },
        {
          id: "book-part",
          label: "Part",
        },
        {
          id: "other",
          label: "Other",
        },
        {
          id: "book",
          label: "Book",
        },
        {
          id: "journal-volume",
          label: "Journal Volume",
        },
        {
          id: "book-set",
          label: "Book Set",
        },
        {
          id: "reference-entry",
          label: "Reference Entry",
        },
        {
          id: "proceedings-article",
          label: "Proceedings Article",
        },
        {
          id: "journal",
          label: "Journal",
        },
        {
          id: "component",
          label: "Component",
        },
        {
          id: "book-chapter",
          label: "Book Chapter",
        },
        {
          id: "proceedings-series",
          label: "Proceedings Series",
        },
        {
          id: "report-series",
          label: "Report Series",
        },
        {
          id: "proceedings",
          label: "Proceedings",
        },
        {
          id: "database",
          label: "Database",
        },
        {
          id: "standard",
          label: "Standard",
        },
        {
          id: "reference-book",
          label: "Reference Book",
        },
        {
          id: "posted-content",
          label: "Posted Content",
        },
        {
          id: "journal-issue",
          label: "Journal Issue",
        },
        {
          id: "dissertation",
          label: "Dissertation",
        },
        {
          id: "grant",
          label: "Grant",
        },
        {
          id: "dataset",
          label: "Dataset",
        },
        {
          id: "book-series",
          label: "Book Series",
        },
        {
          id: "edited-book",
          label: "Edited Book",
        },
      ],
    };
  },
};
</script>

<style>
</style>