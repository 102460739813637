<template>
    <div class="expert-links">
        <div class="d-flex align-items-center">
            <div class="search-links">
                <span class="link-type">Search:</span>
                <a target="_blank" :href='"https://google.nl/search?q="+get_query_string()'><i class="fa-brands fa-google me-1"></i>Google</a>
                <a target="_blank" :href='"https://www.linkedin.com/search/results/all/?keywords="+get_query_string()'><i class="fa-brands fa-linkedin-in me-1"></i>LinkedIn</a>
                <a target="_blank" :href='"https://www.researchgate.net/search/researcher?q="+get_query_string()'>ResearchGate</a>
                <a target="_blank" v-if="!item.fields.ids?.scopus" :href='get_scopus_query_string()'>Scopus</a>
            </div>
            <div class="profile-links" v-if="scholar_details?.scholar_id || item.fields.ids?.orcid || item.fields.ids?.scopus">
                <span class="ms-4 me-1 link-type">Profiles:</span>
                <a v-if="scholar_details.scholar_id" class="" target="_blank" :href="'https://scholar.google.com/citations?user='+scholar_details.scholar_id">
                    <i class="fa-brands fa-google me-1"></i>Scholar</a>
                <a v-if="item.fields.ids?.scopus" class="" target="_blank" :href='item.fields.ids.scopus'>Scopus</a>
                <a v-if="item.fields.ids?.orcid" target="_blank" :href='item.fields.ids.orcid'><i class="fa-brands fa-orcid me-1"></i>Orcid</a>
            </div>
        </div>
        <div class="d-flex align-items-center" v-if="orcid_details.person['researcher-urls']['researcher-url'].length">
            <div class="orcid-links">
                <span class="link-type">Links:</span>
                <a v-for="(external_link, link_num) in orcid_details.person['researcher-urls']['researcher-url']" 
                    :key="link_num" target="_blank" 
                    :href="external_link.url.value">
                    {{external_link['url-name']?external_link['url-name']:external_link.url.value}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import shared from "../shared.js"

export default {
    name: 'ExpertLinks',
    props: ['item', 'orcid_details', 'scholar_details'],
    methods: {
        get_query_string: function() {
            return encodeURIComponent(this.item.fields.display_name + " " + (shared.get_lki(this.item)?.display_name ?? ""))
        },
        get_scopus_query_string: function() {
            let institution_name = shared.get_lki(this.item)?.display_name
            let name_parts = this.item.fields['display_name'].split(' ')
            let last_name = name_parts.slice(-1).join('')
            let first_name = name_parts.slice(0,1).join('')
            return `https://www.scopus.com/results/authorNamesList.uri?sort=count-f&st1=${last_name}&st2=${first_name}&s=AUTHLASTNAME%28${last_name}%29+AND+AUTHFIRST%28${first_name}%29+AND+AFFIL%28${institution_name}%29&origin=searchauthorfreelookup`
        }
    }
}
</script>

<style>
.expert-links {
    display:flex;
    flex-direction:column;
    color: var(--almost_black);
}

.expert-links div {
    align-items:center;
    display:flex;
}

.orcid-links {
    flex-wrap:wrap;
}
.expert-links a {
    border-radius:5px;
    border: 1px solid var(--primary_color);
    text-decoration:none;
    color:var(--primary_color);
    background:white;
    padding: 0 .5rem;
    margin: .25rem .25rem .25rem 0;
    font-size:.8rem;
    white-space:nowrap;
}

.expert-links a:hover {
    cursor:pointer;
    background:var(--primary_color);
    color:white;
}

.link-type {
    width:60px;
}
</style>