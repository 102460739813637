<template>
    <div class="d-flex flex-column" id="works_results">
        <ResultlistTopbar :sortable="sortable" />
        <div v-for="work in $store.getters.getCurrentResultList" :key="work.fields.id" class="result-row p-4">
            <Work :key="work.fields.id" :work="work"/>
        </div>
    </div>
</template>

<script>
import Work from './Work.vue'
import ResultlistTopbar from '../ResultlistTopbar.vue'

export default {
    name: "Works",
    components: {Work, ResultlistTopbar},
    data: function() {return({
        sortable: [
            {
                "name" : "Relevance",
                "field": "fields.ranking_score",
                "default_order": "desc"
            },{
                "name" : "Publication Date",
                "field": "fields.publication_date",
                "default_order": "desc"
            },{
                "name" : "Title",
                "field": "fields.title",
                "default_order": "asc"
            },
        ]
    })},
}
</script>

<style>
</style>