<template>
  <div id="coifinder" class="mb-3">
        <div class="sidebar-title" title="Check for potential conflicts of interest with specific authors.">Conflicts of Interest</div>
            <Multiselect
                mode="tags" 
                label="display_name" 
                valueProp="identifier" 
                :searchable="true" 
                placeholder="Type to find authors"
                v-model="coi_authors" 
                :object="true" 
                :filterResults="false" 
                ref="coiauth_id_multiselect"
                :min-chars="3" 
                :delay="150" 
                id="coiauth_id_multiselect" 
                :canClear="false"
                :attrs='{"autocomplete": "one-time-code"}'
                :class="{'active' : coi_authors?.length}"
                :noOptionsText="'Search by author name (English)'" 
                :options="query_authors">
                <template v-slot:option="{ option }">
                    <div class="d-flex justify-content-between flex-grow-1">
                        <span class="fw-bold">{{option.fields.display_name}}</span>
                        <div><span>{{option.fields.hint}}</span>
                             <span class="ms-2">{{option.fields.works_count}}<i class="fas fa-book ms-1"></i></span>
                        </div>
                    </div>
                </template>
                <template v-slot:caret={}>
                    <span v-if="show.coi_loading"><i class="fas fa-spinner fa-spin me-2"></i></span>
                    <span v-else-if="!coi_authors_has_options"><i class="fas fa-search me-2 text-greyed-out"></i></span>
                    <span v-else class="multiselect-caret" aria-hidden="true"></span>
                </template>
                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                    <div class="multiselect-tag is-user country" :class="{'is-disabled': disabled}" :title="option.fields.display_name">
                        <div class='ellipsis-text'>{{ option.fields.display_name }}</div>
                        <span v-if="!disabled"
                            class="multiselect-tag-remove"
                            @click="handleTagRemove(option, $event)">
                            <span class="multiselect-tag-remove-icon"></span>
                        </span>
                    </div>
                </template>
        </Multiselect>
        <span v-if="$store.getters.getAuthorsWithCoI.length && filter_available('excluded_records')"
            @click="hide_authors_with_conflicts()"
            role="button"
            class="mt-1 align-self-end slider-text hide_coi"
            > 
            Hide {{$store.getters.getAuthorsWithCoI.length}} results with conflicts
        </span>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import shared from '../shared'
import { mapState } from "vuex";

export default {
    name:'CoIFinder',
    components: {Multiselect},
    computed: {
        ...mapState(["searchmodules", "show"]),
        coi_authors: {
            get() {
                return this.$store.state.search.conflict_of_interest?.records??[]
            },
            set(value) {
                this.$store.dispatch('set_conflict_of_interest_records', value)
            }
        }
    },
    data: () => { return ({
        coi_authors_has_options: false,
        coi_loading:0,
    })},
    methods: {
        query_authors: async function(q) {
            let _this = this;
            if (q) {
                return await this.$axiosQ.jumpQueue('openalex', {
                    method: 'get', 
                    url: "https://api.openalex.org/autocomplete/authors?q=" + shared.latinize(q)}, 2).then(function(response) {
                    let short_ids = _.map(response.data.results, shared.make_author_from_oa_suggestion)
                    _this.coi_authors_has_options = (short_ids.length && short_ids.length > 0);
                    return short_ids;
                })
            }
        },
        hide_authors_with_conflicts: function() {
            let excluded_records_batch = []
            this.$store.getters.getAuthorsWithCoI.forEach(id => {
                let record = this.$store.getters.getCurrentResultListByID[id]
                this.$store.state.search.excluded_records.push(record)
                excluded_records_batch.push(record)
            })
            if (this.$store.state.search.uuid) {
                this.$store.dispatch('add_bulk_records_to_search', {
                    'record_list': excluded_records_batch,
                    'record_model': 'excluded_record'
                })
            }
        },
        filter_available: function(field_name) {
            return this.$store.getters.getCurrentlyAvailableFilters.includes(field_name)
        },
    },
    mounted: function() {
        if(this.$store.state.search.conflict_of_interest?.records?.length && this.$store.getters.getCurrentResultList && this.$store.getters.getCurrentResultList.length) {
            console.log('mounted with CoI in list, dispatching check.')
            this.coi_loading = 1
            this.$store.dispatch('check_conflicts_of_interest').then(() => {this.coi_loading = 0})
        }
        else {
            console.log('mounted but no CoI in check.')
        }
    }
}
</script>

<style>
#coifinder {
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
}
.hide_coi:hover {
    color:var(--orange_highlight);
}
</style>