<template>
    <div class="graphcontainer">
      <div class="canvas-container">
        <span class="custom-icon custom-reset-zoom" id="restore_zoom" @click="restore_zoom"></span>
        <v-chart ref="network_graph" 
            id="network_graph" 
            :auto-resize="true" 
            :option="$store.getters['graph_store/getGraphOptions']"
            :loading="loading"
            @mouseover="highlight_author_node"
            @mouseout="reset_author_node"
            :manual-update="true"
        />
        <div id='loading' v-show="show.graph_loading">
          <Spinner/>
        </div>
        <div id="popper_anchor"></div>
        <Teleport to="#hovering_author_container">
          <div id="overlay" @click="show.graph_author_hover=0"></div>
          <div>
              <CloseButton @close="show.graph_author_hover=0" title="Close"/>
              <Expert v-if="show.graph_author_hover" :item="$store.getters.getCurrentResultListByID[detailed_author]" in_graph="1"/>
          </div>
        </Teleport>
      </div>
      <TopicArea
        :hovered_topics="hovered_topics"
        @toggle_topic="toggle_topic($event)"
        @highlight_topic="highlight_topic($event)" 
        @reset_highlights="reset_highlights()"
       />
  </div>
</template>

<script>
import Expert from './Expert/Expert.vue'
import TopicArea from './TopicArea.vue'
import CloseButton from './Utils/CloseButton.vue';
import Spinner from './Spinner.vue';
import { mapState } from 'vuex';
import { createPopper } from '@popperjs/core';
import { dom } from '@fortawesome/fontawesome-svg-core';

// init for the chart library
import VChart from "vue-echarts";
import * as echarts from 'echarts/core';
import { TooltipComponent} from 'echarts/components';
import { GraphChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  CanvasRenderer,
  LabelLayout,
  GraphChart,
  TooltipComponent
]);

export default {
  name: "EChartsGraph",
  components: {Expert, CloseButton, Spinner, VChart, TopicArea},
  computed: {
    ...mapState(['show', 'searchbox_collection']),       
    hovered_topics() {
      if (!this.hovering_author) return []
      return _.map(this.$store.getters.getCurrentResultListByID[this.hovering_author]['fields']['topics'], 'id')
    },
  },
  data: function() {return {
    loading:1,
    hovering_author: false,
  }},
  // Initial load
  mounted: async function() {
    const response = await this.$store.dispatch('graph_store/query_neo4j')
    await this.$store.dispatch('graph_store/update_graph_data', {graph_data: response.data, method:'reset'})
    await this.$store.dispatch('graph_store/update_topic_layer', this.$refs.network_graph)
    await this.$store.dispatch('graph_store/render_graph', {graph_element: this.$refs.network_graph, silent:false, trigger:'mounted'})
    this.loading = 0
    // this is done solely to make the graph accessible for Selenium javascript testing:
    window.EChartsGraph = this.$refs.network_graph
  },
  methods: {
    async toggle_topic(toggled_topic) {
      await this.$store.dispatch('graph_store/update_topic_layer', this.$refs.network_graph)
      await this.$store.dispatch('graph_store/render_graph', {graph_element: this.$refs.network_graph, silent:false, trigger:'topic'})
    },
    // graph buttons
    async expand_graph(author_id) {
      // replace + icon with loader:
      this.loading = 1
      const spinnerIcon = document.createElement('i')
      spinnerIcon.classList.value = 'fa fa-spinner fa-spin'
      let original = document.querySelector('#expand_graph_button svg') 
      original.replaceWith(spinnerIcon)
      dom.i2svg()
      // query noe4j for new collaborators
      const response = await this.$store.dispatch('graph_store/expand_graph', author_id)
      // update the data in the store
      await this.$store.dispatch('graph_store/update_graph_data', {graph_data: response.data, method:'append'})
      // update the topic nodes and relationships
      await this.$store.dispatch('graph_store/update_topic_layer', this.$refs.network_graph)
      // render the graph.
      await this.$store.dispatch('graph_store/render_graph', {graph_element: this.$refs.network_graph, silent:false, trigger:'append'})
      // restore original icon
      const original_icon = document.createElement('i')
      original_icon.classList.value = 'fa fa-plus'
      document.querySelector('#expand_graph_button svg').replaceWith(original_icon)
      dom.i2svg()
      this.loading = 0
    },
    /////////////////////////////////
    // Mouse interaction Functions //
    /////////////////////////////////
    // 
    show_author_layout(evt, author_id) {
      this.show.graph_author_hover = 0
      if(this.detailed_author == author_id) {
        this.detailed_author = false;
        return false
      }
      let popper_anchor = document.getElementById('popper_anchor')
      popper_anchor.style.top = evt.layerY+'px'
      popper_anchor.style.left = evt.layerX+'px'
      let author_container = document.getElementById('hovering_author_container')
      this.author_infobox = createPopper(popper_anchor, author_container, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 60],
                },
            },
            {
              name: 'arrow',
              options: {
                  padding: 16, // 16px from the edges of the popper
              },
            },  
        ],
      })     
      this.detailed_author = author_id;
      this.show.graph_author_hover = 1
    },
    highlight_author_node(params) {
      let _this = this;
      if (params.dataType === 'node') {
        this.$refs.network_graph.dispatchAction({
          type: 'showTip',
          seriesIndex: params.seriesIndex,
          dataIndex: params.dataIndex,
        })
        // only set hovering author when the node is an author (not topic)
        if (params.data.category == 'author') {
          this.hovering_author = params.data.id
          document.getElementById('show_author_details_button').addEventListener("click", function(event) {_this.show_author_layout(event, params.data.id)})
          document.getElementById('expand_graph_button').addEventListener("click", function() {_this.expand_graph(params.data.id)})
        }
      }
      // ignore hovers on edges
      else {
        this.$refs.network_graph.dispatchAction({
              type: 'downplay',
              seriesIndex: 0,
          });
        return false;
      }
    },
    reset_author_node() {
      this.hovering_author = false,
      this.$refs.network_graph.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
      });
      this.$refs.network_graph.dispatchAction({type: 'hideTip'})
    },
    restore_zoom() {
      console.log('zooming')
      this.$refs.network_graph.setOption({
        series: {
          zoom: 0,
          center: ['50%', '50%']
        }
      })
    },
    async highlight_topic(topic) {
      // if we're hovering over a topic, we cannot also be hovering over an author:
      this.reset_author_node();
      await this.$store.dispatch('graph_store/highlight_topic', topic.id)
      this.$store.dispatch('graph_store/render_graph', {graph_element: this.$refs.network_graph, silent:true, trigger:'highlight'})
    },
    async reset_highlights() {
      await this.$store.dispatch('graph_store/highlight_topic', false)
      this.$store.dispatch('graph_store/render_graph', {graph_element: this.$refs.network_graph, silent:true, trigger:'reset_highlight'})
    },
  }
};
</script>

<style>
.graphcontainer {
  width: 100%;
  max-width:100vw;
  z-index:1;
  overflow:hidden;
  display: flex;
  flex-direction:row;
  flex-grow:1;
}
@media (max-width: 992px) {
  .graphcontainer {
    flex-direction:column;
  }
  .canvas-container {
    margin: 0px .5rem;
    max-width: 95vw!important;
  }
  #network_graph {
    height:calc(100vh - 300px - 120px)!important;
  }
}
.graph-interaction {
  position: absolute;
  top: 30px;
  left:10px;
  z-index:2;
  margin-top:calc(-32px + .5rem);
}
/* Hovering hex */
.popup-container {
  display:inline-block!important;
  background:transparent!important;
  border:none!important;
  box-shadow:none!important;
  margin-left:-56px;
}
.hex-container {
  display:inline-block;
  position:relative;
  z-index:2;
}
.hex-button {
  display:flex;
  justify-content:center;
  color:white;
  width: 60px;
  height: 32px;
  cursor:pointer;
  padding-top:8px;
}
.hex-button:hover {
  background-color:var(--orange_highlight)!important;
}
.hex-button.top {
  background-color: #C1E9E6;
  clip-path: polygon(0% 50%, 50% 0%, 100% 50%, 100% 100%, 0% 100%);
  margin-bottom:-1px;
}
.hex-button.bottom {
  margin-top:-1px;
  background-color: #A0DCD9;
  clip-path: polygon(0% 50%, 50% 100%, 100% 50%, 100% 0%, 0% 0%);
}
.spacer {
  background-color: var(--background_color);
  width:60px;
  height:12px;
  border-left: 1px solid var(--grey_divider)
}
.circle {
  z-index:3;
  background-color:var(--primary_color);
  border-radius:50%;
  position:absolute;
  transform: translate(-50%, -50%);
  top:50%;
  left:50%;
 }
.display_name {
  margin-left:-1px;
  display:inline-block;
  position:absolute;
  top: 33px;
  background-color: var(--background_color);
  font-weight: 400px;
  color: var(--secondary_color);
  padding: .25rem .5rem;
  border-top: 1px solid var(--grey_divider);
  border-right: 1px solid var(--grey_divider);
  z-index:1;
  border-bottom: 1px solid var(--grey_divider);
  box-shadow: var(--box_shadow_emphasis);
}

.btn-primary.active {
  /* font-weight:800!important; */
  color:white!important;
  background-color: var(--primary_color)!important;
}
.graph-interaction span:hover {
  color:var(--primary_color);
}
#popper_anchor {
  position:absolute;
}
.custom-icon {
  background-color:black;
  position:absolute;
  margin: .5rem;
  width:1.5rem;
  height: 1.5rem;
  overflow: visible;
  z-index:5;
  vertical-align: -0.125em;
  overflow:visible;
  display:inline-block;
}
.custom-reset-zoom {
  mask: url("../assets/img/icons/reset-zoom.svg"); 
  mask-size:cover;
  -webkit-mask: url("../assets/img/icons/reset-zoom.svg");
  -webkit-mask-size: cover;
}
.custom-reset-zoom:hover {
  background-color:var(--primary_color);
}
#network_graph {
  /* border: 1px solid var(--grey_divider); */
  box-shadow: var(--box_shadow);
  background-color:#ffffff75;
  width:100%;
  height: 100%;
  max-height: calc(100vh - 130px - 24px - 40px - 24px);
  border-left: 1px solid var(--grey_divider);
  border-right: 1px solid var(--grey_divider);
  border-bottom: 1px solid var(--grey_divider);
}
#colorpicker {
  color:rgb(156, 156, 156);
}

#loading {
  position:absolute;
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
#overlay {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  z-index:3;
}
canvas {
  cursor:grab;
}
.canvas-container {
  position:relative;
  display:flex;
  flex: auto;
  max-width: calc(100vw - 260px - 210px);
  height: calc(100vh - 130px - 24px - 40px - 24px);
}
</style>