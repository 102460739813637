<template>
    <div v-show="item.fields" :id="short_id" class="expert-row d-flex justify-content-end py-4 align-items-start w-100 flex-grow-1">
        <div class="d-flex flex-column ps-4">
            <img v-if="org_image" class="profile-pic pic-institution" :src="org_image"/>
            <div v-else class="profile-pic org-svg"></div>
        </div>
        <div class="d-flex flex-column expert-header px-3 flex-grow-1">
            <div class="d-flex flex-column expert-textinfo flex-column justify-content-between">
                <div class="expert-name">{{item.fields.display_name}} ({{item.fields.country_code}})</div>
                <div class="d-flex expert-links">
                    <div class="profile-links">
                    <span class="capitalize">{{item.fields.type}}</span>
                        <a class="" :href="item.fields.homepage_url"><i class="fa fa-globe"></i> Website</a>
                    </div>
                </div>
                <CollectionItems  :item="item" :pubs="item.collection.records"></CollectionItems>
            </div>
        </div>
    </div>
</template>

<script>
import LabelBox from '../LabelBox.vue'
import CollectionItems from "../CollectionItems.vue";

export default {
    name: "Institution",
    components: {LabelBox, CollectionItems},
    computed: {
        short_id() {
            return this.item.fields.id.replace('https://openalex.org/', '')
        },
        uniqueRecords() {
            return _.uniqBy(this.collection, 'fields.id')
        },
        org_image() {
            if (this.item.fields.image_thumbnail_url) {
                return decodeURIComponent(this.item.fields.image_thumbnail_url.replaceAll('%%', '%'))
            }
            else return false
        }
    },
    props: ["item", "collection"],
}
</script>

<style>
    .pic-institution {
        object-fit:contain!important;
        -o-object-fit:contain!important;
    }
    .org-svg {
        background-color: var(--primary_color);
        -webkit-mask: url('../../assets/img/icons/building.svg') no-repeat center;
        mask: url('../../assets/img/icons/building.svg') no-repeat center;
    }
    .capitalize {
        text-transform: capitalize;
    }
    .badge.link {
        text-decoration:none;
    }
    .organisation-logo, .organisation-logo img {
        width:90px;
        min-width:90px;
    }
    .publication_title {
        color:var(--secondary_color);
    }
</style>