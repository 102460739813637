<template>
    <div id="topic-area" class="ms-2">
        <span class="topic_btn me-1 mb-1" 
            :class="get_topic_class(topic)"
            @mouseover="$emit('highlight_topic', topic)" 
            @click="toggle_topic(topic)" 
            @mouseleave="$emit('reset_highlights')"
            :title="topic.domain.display_name"
            :id="'topic_toggle_'+topic.id"
            :key="topic.id" 
            v-for="topic in $store.state.topics">
            {{topic.display_name}}
        </span>
    </div>
</template>

<script>
export default {
    name: "TopicArea",
    props: ['hovered_topics'],
    methods: {
        get_topic_class(topic) {
            const classList = []
            classList.push('domain_'+topic.domain.id)
            if(this.hovered_topics.indexOf(topic.id)>-1) {classList.push('hover')}
            if(this.$store.getters.getActiveTopicIds.includes(topic.id)) {classList.push('active')}
            return classList
        },
        toggle_topic(topic) {
          this.$store.commit('TOGGLE_ACTIVE_TOPIC', topic)
          this.$emit('toggle_topic', topic)
        }
    }
}
</script>

<style>
@media (max-width: 992px) {
  #topic-area {
    margin-top:.5rem;
    max-width:100%;
  }
}
@media (min-width:992px) {
  #topic-area {
    max-width:360px;
  }
}
#topic-area {
  display:flex;
  flex-direction:column;
}
#topic-area .hover {
  background-color: var(--orange_highlight)!important;
  color:white;
}
.topic_btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  color: var(--primary_color);
  border-radius: var(--ms-radius);
  box-shadow: var(--box_shadow_emphasis);
  background: white;
}
.topic_btn.active, .topic_btn:hover {
  color:white;
}
.domain_1{border-right: 4px solid var(--domain_1_color)!important;}
.domain_1:hover, .domain_1.active{background-color: var(--domain_1_color)!important;}
.domain_2{border-right: 4px solid var(--domain_2_color)!important;}
.domain_2:hover, .domain_2.active{background-color: var(--domain_2_color)!important;}
.domain_3{border-right: 4px solid var(--domain_3_color)!important;}
.domain_3:hover, .domain_3.active{background-color: var(--domain_3_color)!important;}
.domain_4{border-right: 4px solid var(--domain_4_color)!important;}
.domain_4:hover, .domain_4.active{background-color: var(--domain_4_color)!important;}
</style>