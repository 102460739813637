<template>
  <div @click.stop="">
    <div class="position-absolute float-end close-button close-button-work" @click="$store.commit('CLOSE_MODAL')">
        <i class="fa fa-close"></i>
    </div>
    <!-- <h3 class="text-center modal-title m-4">{{modals.coi_details.check.display_name}} with {{modals.coi_details.against.display_name}}</h3> -->
    <div class="modal-content">
        <div class="d-flex flex-grow-1">
            <div class="d-flex flex-column flex-start me-2 p-4 sidebar" >
                <div class="d-flex flex-column" id="coi_sidebar_top">
                    <div id="check_authors" class="d-flex flex-column align-items-start work-authors">
                        <div class="sidebar-title">Possible conflicts of</div>
                        <div class="d-flex align-items-center" :key="'against'+author.identifier" v-for="author in coi_store.check">
                            <SmallAuthorBadge :author="author"/>
                            <span title="Has coauthorships"
                                    v-if="$store.getters['coi_store/getCoAuthorIds'].has(author.identifier)">
                                <i class="ms-1 fa fa-pencil"></i>
                            </span>
                            <span title="Shares historical affiliation" 
                                    v-if="$store.getters['coi_store/getCoaffiliatedIds'].size > 1 &&
                                        $store.getters['coi_store/getCoaffiliatedIds'].has(author.identifier)">
                                <i class="ms-1 fa fa-institution"></i>
                            </span>
                        </div>
                    </div>
                    <div id="against_authors" class="d-flex flex-column align-items-start mt-2 work-authors">
                        <div class="sidebar-title">with</div>
                        <div class="d-flex align-items-center" :key="'against'+author.identifier" v-for="author in coi_store.against">
                            <SmallAuthorBadge :author="author"/>
                            <span title="Has coauthorships" v-if="$store.getters['coi_store/getCoAuthorIds'].has(author.identifier)">
                                <i class="ms-1 fa fa-pencil"></i>
                            </span>
                            <span title="Shares historical affiliation"
                                    v-if="$store.getters['coi_store/getCoaffiliatedIds'].size > 1 &&
                                        $store.getters['coi_store/getCoaffiliatedIds'].has(author.identifier)">
                                <i class="ms-1 fa fa-institution"></i>
                            </span>
                        </div>                        </div>
                </div>
                <div id="coi_sidebar_bottom" class="mt-4">
                    <div class="sidebar-title">Since</div>
                    <div class="input-group mb-3">
                        <input id="start_year_input" class="form-control" v-model="since_year" step="1" min="2000" type="number"/>
                    </div>
                    <div class="slider mb-3" role="button" 
                        :class="coi_store.only_first2_last2_authors?'active':'inactive'"
                        @click="coi_store.only_first2_last2_authors = 1 - coi_store.only_first2_last2_authors">
                        <div class="slider-space">
                            <span class="slider-toggle"></span>
                        </div>
                        <div class="sidebar-title ms-2 ">First 2 and last 2 authors</div>
                    </div>
                </div>
            </div>
            <div id="coi_results" class="d-flex flex-column flex-grow-1 p-4">
                <div v-if="$store.state.coi_store.loading.affiliations">
                    <Spinner />
                </div>
                <div class="affiliation-box mt-2 mb-4" v-if="$store.getters['coi_store/getCoaffiliatedIds']?.size > 1">
                    <h5 class="mb-3">Affiliations</h5>
                    <table id="shared_aff_table" class="table">
                        <thead>
                            <th class="inst_name"></th>
                            <th class="year text-center" :key="'h'+year" v-for="year in timeline">{{year}}</th>
                        </thead>
                        <tbody>
                            <template v-for="(authors, institution) in $store.getters['coi_store/getSharedAffiliations']">
                                <tr :key="institution+author" v-for="(years, author, index) in authors">
                                    <th class="inst_name" :rowspan="Object.keys(authors).length" v-if="index==0">{{get_institution_name(institution)}}</th>
                                    <td v-for="(author_columns, year) in get_author_colspans(years)" 
                                        :key="institution.id+'int'+year" 
                                        :colspan="author_columns.span" 
                                        :class="'aff_row_'+index" 
                                        class="text-center">
                                        <SmallAuthorBadge class="w-100" v-if="author_columns.active" :author="$store.getters['coi_store/getAllCoiAuthorsById']?.[author]"/>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div v-if="$store.state.coi_store.loading.publications">
                    <Spinner />
                </div>
                <div v-if="$store.getters['coi_store/getSharedPublications'].length">
                    <h5 class="mb-3">Publications</h5>
                    <div class="result_list">
                        <div class="my-2" :key="'shared_pub'+publication.id" v-for="publication in $store.getters['coi_store/getSharedPublications']">
                            <Work class="" :default_open="true" :in_popup="false" :work='{fields: publication}' />
                            <hr/>
                        </div>
                    </div>
                </div>
                <div v-if="$store.getters['coi_store/getCoaffiliatedIds']?.size < 2 &&
                            !$store.getters['coi_store/getSharedPublications'].length">
                    <div>With these filters, no overlapping affiliations or publications remain for the selected authors.</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SmallAuthorBadge from '../Expert/SmallAuthorBadge.vue'
import Spinner from '../Spinner.vue'
import Work from '../Work/Work.vue'

export default {
    name: 'CoiDetailModal',
    props:['modal'],
    components: {Work, SmallAuthorBadge, Spinner},
    computed: {
        ...mapState(['modals', 'coi_store']),
        since_year: {
            get() {return this.coi_store.since_year},
            set(value) {this.coi_store.since_year = value}
        },
        timeline: function() {
            let _this = this;
            return _.range(_this.coi_store.since_year, new Date().getFullYear()+1, 1)
        },
    },
    data: function() { return {
        crossref_options: [
            {name: "Journal Articles", id: "journal-article"},
            {name: "Proceedings", id: "proceedings"},
            {name: "Preprints and posted content", id: "posted-content"},
            {name: "Database", id: "database"},
        ],
        }
    },
    methods: {
        // this function turns a series of years [2024, 2023, 2022, 2018, 2019] into 
        // colspans {2018: {span:2, active: true}, 2020: {span: 1, active: false}, 2021: {span: 1, active:false} 2022: {span: 3, active:true}}
        get_author_colspans: function(affiliation_years) {
            let response = {}
            let years_in_chain = []
            this.timeline.forEach(year => {
                if(affiliation_years.includes(year) && !years_in_chain.includes(year)) {
                    let i = 1
                    while (affiliation_years.includes(year + i)) {
                        years_in_chain.push(year+i)
                        i++
                    }
                    response[year] = {
                        active: 1,
                        span: i
                    }
                }
                else if(!years_in_chain.includes(year)) {
                    response[year] = {
                        active:0,
                        span:1,
                }}
            })
            return response
        },
        get_institution_name: function(institution_id) {
            return this.$store.state.apicache['openalex/'+institution_id]?.['display_name']
        }
    }
}
</script>

<style scoped>
.modal-content {
    background-image: url('../../assets/img/gc_background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
}
.result_list {
    padding-bottom:1.5rem;
    max-width:80vw;
}
.sidebar {
    min-width:300px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: var(--box_shadow);
}
.modal-title {
    position:sticky;
}
.modal-content {
    max-height: calc(80vh);
}
#coi_results {
    overflow-y:auto;
    max-height: calc(80vh);
}
.work-authors svg {
    color:var(--primary_color);
}
/* Affiliation Table */
#shared_aff_table th, #shared_aff_table td{
    background: transparent;
}
#shared_aff_table {
    vertical-align: middle;
    table-layout:fixed;
    border:transparent;
}
#shared_aff_table tr:hover {
    background: #ffffff10;
}
#shared_aff_table th:first-child,
#shared_aff_table td:first-child {
    width: 200px; /* Allow first column to resize */
    white-space: nowrap; /* Prevent wrapping in first column */
}
.inst_name {
    padding-left:0;
}
.inst_name, .year, #shared_aff_table tr:last-child td{
    border-bottom:2px solid var(--grey_divider);
}
.aff_row_0 {
    border-top:2px solid var(--grey_divider);
}
#shared_aff_table th:not(:first-child),
#shared_aff_table td:not(:first-child) {
    width: 1fr; /* Distribute remaining columns equally */
}

/* Slider UI element */
.slider {
    justify-content:flex-start;
    align-items:baseline;
}
.slider.active .slider-header{ 
    color: var(--primary_color)!important;
}
.slider.active .slider-toggle {
    margin-left:14px;
    background: var(--primary_color);
}
.slider.inactive .slider-toggle {
    margin-left:1px;
    background:white;
}
.active .sidebar-title{
    color: var(--primary_color);
  }
</style>