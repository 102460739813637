<template>
  <span class="grey-divider" :class="margin_class">|</span>
</template>

<script>
export default {
    name: "grey-divider",
    props: ['margin_class']
}
</script>

<style>
.grey-divider{
    color:var(--grey_divider);
}
</style>