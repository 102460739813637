<template>
    <div>
        <ResultlistTopbar/>
        <table class="table table-hover">
            <!-- <thead>
                <th class="col-11">Submission</th>
                <th class="col-1 float-end">Links</th>
            </thead> -->
            <tbody>
                <tr :fields="policy_doc.fields" v-for="policy_doc in list" :key="policy_doc.id">
                    <td>
                        <span class="badge me-1"><small><i class="fas fa-calendar"></i> {{new Date(1000* policy_doc.fields.publication_date).toLocaleDateString()}}</small></span>
                        <span class="badge me-1"><small><i class="fas fa-institution"></i> {{policy_doc.fields.id.split('-')[0].toUpperCase()}}</small></span>
                        <a v-if="policy_doc.fields.url" target="_blank" role="button" class="badge text-decoration-none" :href="policy_doc.fields.url"><small><i class="fas fa-globe"></i> Source</small></a>
                        <h5 class="mt-1">{{policy_doc.fields.title}}</h5>
                        {{policy_doc.fields.text}}
                    </td>
                    <td>
                        <a class="badge bg-info text-decoration-none" target="_blank" :href='get_query_string(policy_doc)'><i class="fa-brands fa-google"></i> Google</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import ResultlistTopbar from '../ResultlistTopbar.vue'

export default {
    name: "PolicyDocs",
    components: {ResultlistTopbar},
    props: ["list"],
    methods: {
        get_query_string: function(policy_doc) {
            return encodeURIComponent("https://google.nl/search?q=" + policy_doc.fields.title + " " + policy_doc.fields.id.split('-')[0])
        },
    }
}
</script>

<style>

h5 {
    color: var(--secondary_color);
}

</style>