<template>
    <div id="content-container"  class="d-flex"  :class='{"no-height": this.show.emphasis !== "SimilarResults"}'>
        <Sidebar v-show="$store.state.show.sidebar"></Sidebar>
        <div id="list-container" class="pt-4 flex-grow-1" v-if="$store.getters.getResultVisible">
            <div v-if="!$store.getters.getCurrentResultList">
                <h6 class="mt-2">Unfortunately, your search did not provide results. Please try to search with less strict filters, or a different text.</h6>
            </div>
            <div v-else class="d-flex flex-grow-1">
                <transition name="component-fade" mode="out-in">
                    <component v-if="$store.getters.getCurrentResultList 
                                     && Object.entries($store.getters.getCurrentResultList).length"
                                v-bind:is="this.$store.state.result_type">
                    </component>
                </transition>
              <PopupContainer/>
            </div>
        </div>
        <ModalContainer/>
    </div>
</template>

<script>
import Sidebar from './Sidebar/Sidebar.vue'
import Projects from './Project/Projects.vue'
import Startups from './Startup/Startups.vue'
import Starthubs from './Startup/Starthubs.vue'
import Experts from './Expert/Experts.vue'
import Works from './Work/Works.vue'
import work from './Work/Work.vue'
import Institutions from './Institution/Institutions.vue'
import PolicyDocs from './PolicyDocs/PolicyDocs.vue'
import Partners from './Partner/Partners.vue'
import Venues from './Venues/Venues.vue'
import ModalContainer from './Modals/ModalContainer.vue'
import PopupContainer from './Modals/PopupContainer.vue'

import { mapState } from "vuex";

export default {
    name: "similarresults",
    components: {Sidebar, Projects, Startups, Starthubs, Experts, Works, Partners, PolicyDocs, Institutions, Venues, work, ModalContainer, PopupContainer},
    computed: {
        ...mapState(["show"]),
    },
    data: function() {return({
        })
    },
    mounted: function() {
        console.log('result visible '+this.$store.getters.getResultVisible)
    },
}
</script>

<style>
#content-container{
    max-width:1440px;
    margin:auto;
    padding: 0 .5rem;
}
#content-container.no-height {
    height:0;
    overflow-y:hidden;
}

@media (max-width: 992px) {
  #content-container{
      flex-direction:column;
    }
    :root {
      --sidebar-width: 100%;
    }
}

#list-container {
    display:flex;
    align-items:stretch;
}

#hovering_author_container {
  position:absolute;
  top:0;
  left:0;
  background-color:white;
  border-radius:var(--ms-radius);
  box-shadow: var(--box_shadow_emphasis);
  z-index:32;
  max-width:940px;
  overflow-x:hidden;
}

#list-container .btn-secondary {
  color: var(--primary_color);
  background-color:transparent;
  border:none;
  padding: 0.25rem 0.5rem;
  font-size: .8rem;
  padding:0 .5rem 0 0 !important;
}
#list-container .btn-secondary:hover {
  color: var(--orange_highlight);
}

.result-row {
    box-shadow: var(--box_shadow);
}
.result-row:hover {
    background: #075F630D;
}

.icon-btn {
  color: var(--primary_color);
}
.icon-btn:hover {
  color: var(--orange_highlight);
}
a.badge:hover {
  color: var(--orange_highlight);
}

.component-fade-enter-from, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.component-fade-enter-to, .component-fade-leave-from
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 1;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: all 0.5s ease;
}

.popup-details {
    max-height:40vh;
    max-width:var(--resultlist_width);
    overflow-y:auto;
    opacity:1;
    background: white;
    margin: .5rem;
    border-radius: 4px;
    font-size: 13px;
    border: solid grey 1px;
    padding:1rem;
    z-index:2;
    box-shadow: 0px 0px 6px grey;
}
#arrow {
    position: absolute;
    width: 8px;
    margin-top: 4px;
    height: 8px;
    z-index: 0;
    transform: translate(50px) rotate(45deg)!important;
    background: white;
    border: 1px solid black;
    border-right: transparent;
    border-bottom: transparent;
}

div[data-popper-placement^='top'] #arrow{
    position: absolute;
    width: 8px;
    margin-bottom: 4px;
    height: 8px;
    z-index: 0;
    bottom:0px;
    transform: translate(50px) rotate(45deg)!important;
    background: white;
    border: 1px solid black;
    border-left: transparent;
    border-top: transparent;
}

  #popup[data-popper-placement^='top'] > #arrow{
    bottom: -4px;
  }
  
  #popup[data-popper-placement^='bottom'] > #arrow{
    top: -4px;
  }
  
  #popup[data-popper-placement^='left'] > #arrow{
    right: -4px;
  }
  
  #popup[data-popper-placement^='right'] > #arrow{
    left: -4px;
  }
</style>