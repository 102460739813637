<template>
    <div class="d-flex flex-grow-1 flex-column">
        <ResultlistTopbar :sortable="sortable" />
        <Starthub :item="starthub" v-for="starthub in $store.getters.getCurrentResultList" :key="starthub.identifier"/>
    </div>
</template>

<script>
import Starthub from './Starthub.vue'
import ResultlistTopbar from '../ResultlistTopbar.vue'

export default {
    name: "Starthubs",
    components: {Starthub, ResultlistTopbar},
    data: function() {return({
        sortable: [
            {
                "name" : "Relevance",
                "field": "fields.ranking_score",
                "default_order": "desc"
            },{
                "name" : "Startup name",
                "field": "fields.company_name",
                "default_order": "asc"
            },{
                "name" : "Submission time",
                "field": "fields.creation_date_time",
                "default_order": "desc"
            },
        ]
    })},
}
</script>

<style>

</style>