<template>
  <div :style="getColorStyling()">
    <navbar></navbar>
  </div>   
</template>

<script>
import navbar from '../components/Navbar.vue'
import shared from '../components/shared'
import {mapState} from 'vuex'

export default {
  name: 'APIDocs',
  components: {navbar},
  computed: {
    ...mapState(['user_profile']),
    colorMap: shared.colorMap
  },
  methods: {
    getColorStyling: function() {
      return shared.colorMap(this.user_profile.organisation.name)
    }
  },
  created: function() {
    this.$store.commit('SET_USER',JSON.parse(document.getElementById('user-data').text));
  },
}
</script>

<style>
#link-operations-top {
  display:none;
}
</style>
