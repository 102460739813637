<template>
      <div id="works-count-filter" title="Here you can provide a range of total published works for authors." class="mb-5">
        <div class="sidebar-title">Total work Count</div>
        <Slider 
            class="range-slider"
            :data-active="slider_active"
            :tooltips="false"
            v-model="works_count_value"
            :min="0"
            :lazy="true"
            :max="max_slider_value"
        />
        <div class="fixed_inputs">
            <div class="float-start">
                <input id="works-count-start" @change="set_range_start" :value="get_range_start()" class="form-control range-input" type="number" step="1"/>
            </div>
            <div class="float-end">
                <input id="works-count-end" @change="set_range_end" :value="get_range_end()" class="form-control range-input" type="text" inputmode="numeric" step="1"/>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from '@vueform/slider'
import {mapState} from 'vuex'
import shared from '../../shared.js'

export default {
    name: 'WorksRangeFilter',
    components: {Slider},
    computed: {
        ...mapState(['filters']),
        slider_active: function() {
            if (this.slider && (this.get_range_start() !== 0 || this.get_range_end() != 'inf')) {
                this.slider.style.background = shared.colorMap()['--primary_color']
                return true
            }
            else if (this.slider) {
                this.slider.style.background = shared.colorMap()['--grey_divider']
                return false;
            }
        },      
        works_count_value: { 
            get() {
                if (this.filters['works_count']) {
                    return [
                        this.filters['works_count']['start'],
                        this.filters['works_count']['end']??this.max_slider_value
                    ]
                }
                else return [0,this.max_slider_value]
            },
            set(value) {
                if (value[1] >= this.max_slider_value) value[1] = null
                this.filters['works_count'] = {
                    'field': 'works_count',
                    'type': 'range',
                    'start': value[0],
                    'end': value[1] 
                }
            }
        }
    },
    data: function() {return({
        slider: false,
        max_slider_value: 250
    })},
    mounted: function() {
        this.slider = document.getElementsByClassName('slider-connect')[0]
    },
    methods: {
        get_range_start() {return this.filters.works_count?.start??'0'},
        set_range_start(evt) {
            var new_value = evt.target.value
            let range_end = parseInt(this.get_range_end()) || null
            if (new_value <= 0) {
                new_value = 0
            } //selected start cannot be negative
            if (new_value >= this.max_slider_value) {
                new_value = (this.max_slider_value -1)
            } //selected value cannot be higher than max
            else if (new_value !== null && range_end !== null && new_value >= range_end) {
                new_value = (range_end - 1)
            }
            this.filters['works_count'] = {
                'field': 'works_count',
                'type': 'range',
                'start': new_value,
                'end': range_end
            }
        },
        get_range_end() {return this.filters.works_count?.end??'inf'},
        set_range_end(evt) {
            var new_value = evt.target.value
            let range_start = parseInt(this.get_range_start())
            if (new_value >= this.max_slider_value) {new_value = null}
            if (new_value !== null && new_value <= range_start) {
                new_value = (range_start + 1)
            }
            this.filters['works_count'] = {
                'field': 'works_count',
                'type': 'range',
                'start': range_start,
                'end': new_value
            }
        },
    }
}
</script>

<style>
.range-slider {
    margin:1.5rem 46px 0 46px;
}
.range-input::-webkit-outer-spin-button, .range-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
#works-count-start, #works-count-end {
    padding:6px 4px!important;
    text-align:center;
    max-width:36px;
    appearance: textfield;
    -moz-appearance: textfield;
}
.fixed_inputs {
    position:relative;
    top:-20px;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
