<template>
    <div>
        <div class="punchcard-container">
            <div id="topics-chart">
                <div class="graph-blocker" v-if="!$store.getters.getActiveTopicIds.length">
                    <span class="graph-blocker-text">Please select a topic on the right to compare.</span>    
                </div>
                <VChart ref="topic_punchcard" id="topic-punchcard" :auto-resize="true" :option="option" @mouseout="reset_highlight" @mouseover="capture_mouseover"/>
            </div>    
            <TopicArea :hovered_topics="hovered_topics" />
        </div>
    </div>
</template>

<script>
import VChart from "vue-echarts";
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { ScatterChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from "echarts/renderers";
import shared from "./shared.js"
import TopicArea from './TopicArea.vue'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  ScatterChart,
  CanvasRenderer,
  UniversalTransition
]);

export default {
    name:"TopicsChart",
    props: [],
    components: {VChart, TopicArea},
    computed: {
        chart_data: function() {
            let _this = this;
            // this function calculates the topic scores for all Experts.
            // the topics are in descending order of query importance
            // the experts are ranked according to the current active sort
            // the data is added as [query_topic_idx, expert_idx, score] to a series
            // in total, there are 4 series, corresponding to each of the top level OpenAlex domains
            let shared_series_settings = {
                type: 'scatter',
                symbolSize: function (val) {
                    return Math.max(Math.min(45, val[2]), 15);
                },
            }
            let series = [
                {...shared_series_settings,
                    name: 'Life Sciences',                   
                    data: [],
                },
                {...shared_series_settings,
                    name: 'Social Sciences',                   
                    data: [],
                },
                {...shared_series_settings,
                    name: 'Physical Sciences',                   
                    data: [],
                },
                {...shared_series_settings,
                    name: 'Health Sciences',                   
                    data: [],
                },
            ]
            let topic_ids = []
            // we reverse the expert results, because the coordinate system starts at the bottom, 
            // but we want the top results to be displayed at the top.
            let expert_results = _this.$store.getters.getCurrentResultList
            let expert_ids = expert_results.map(item => {return item.identifier}).reverse()           
            // for every diplayed topic, we loop over all authors, and for each author we loop over all topics.
            _.forEach(this.$store.getters.getActiveTopics, function(topic, topic_idx) {
                topic_ids.push(topic.id)
                expert_results.forEach(function(expert, expert_idx) {
                    if(_this.$store.getters['getAuthorCountsByTopic'][topic.id]?.[expert.identifier]){
                        const count = _this.$store.getters['getAuthorCountsByTopic'][topic.id][expert.identifier]
                        // The data is a 3-integer array, namely: [topic_idx, expert_index, work_count]
                        series[topic.domain.id -1 ]['data'].push([ 
                            topic_idx,
                            expert_ids.length - 1 - expert_idx,
                            count
                        ])
                    }
                })
            })
            let chart_data_obj = {
                "series": series,
                "topics": topic_ids,
                "experts": expert_ids
            }
            return chart_data_obj
        },
        option: function(){
            let _this = this;
            return {
                legend: {
                    left: 'left',
                    top: 'bottom'
                },
                color: _.map(shared.domain_colors, (domain) => {return domain.bg}),
                grid: {
                    left: 200,
                    top: 150,
                    containLabel: false
                },
                tooltip: {
                    show: true,
                    formatter: function(item) {
                        if(!item.value) return
                        else {
                            let expert_id = _this.chart_data.experts[item.value[1]]
                            let topic_id = _this.chart_data.topics[item.value[0]]
                            return `${_this.$store.getters.getCurrentResultListByID[expert_id].fields.display_name} has ${item.value[2]} works in ${shared.topics[topic_id].dn}`
                        }
                    }
                },
                yAxis: {
                    type: 'category',
                    data: _this.chart_data.experts,
                    boundaryGap: false,
                    invert: true,
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show:false,
                    },
                    axisLine: {
                        show: false
                    },
                    triggerEvent: true,
                    axisPointer: {
                        show: true,
                        type: 'shadow',
                        triggerTooltip: true,
                        label: {
                            formatter: function(axis_item) {
                                return `${_this.$store.getters.getCurrentResultListByID[axis_item.value].fields.display_name}`
                            },
                        },
                        animation: false
                    },
                    axisLabel: {
                        formatter: function(axis_item) {
                               return  `{hovering_label|${_this.$store.getters.getCurrentResultListByID[axis_item].fields.display_name}}`;
                        },
                        rich: {
                            hovering_label: {
                                cursor: 'pointer'
                            }
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    data: _this.chart_data.topics,
                    position: 'top',
                    triggerEvent: true,
                    axisPointer: {
                        show: true,
                        type: 'shadow',
                    },
                    axisLine: {show: false},
                    axisTick: {show: true},
                    axisPointer: {
                        show: true,
                        label: {
                            show:false,
                        },
                        type: 'shadow',
                        animation: false
                    },
                    axisLabel: {
                        formatter: function(x_value) {
                            return shared.topics[x_value].dn
                        },
                        rotate: -25,
                        margin: 28,
                        width:150,
                        overflow:'truncate',
                    },
                    
                },
                series: this.chart_data.series,

            }
        },
        hovered_topics() {
            if (!this.hovering_author) return []
            return _.map(this.$store.getters.getCurrentResultListByID[this.hovering_author]['fields']['topics'], 'id')
        },
    },
    data: function() {return{
        hovering_author: false,
    }},
    mounted: function() {
    },
    methods: {
        capture_mouseover: function(event) {
            let _this = this;
            if (event.componentType == 'yAxis') {
                _this.$refs.topic_punchcard.dispatchAction({
                    type: 'highlight',
                    seriesIndex:0,
                    dataIndex: event.dataIndex
                })
                _this.hovering_author = event.value
            }
        },
        reset_highlight: function(event) {
            this.hovering_author = false
        }
    }
}


</script>

<style>
.punchcard-container {
    display:flex;
    position:relative;
    left:0;
    top:0;
}
@media (max-width: 992px) {
  .punchcard-container {
    flex-direction:column;
  }
}
#topics-chart {
    width:var(--resultlist_width);
    min-height:80vh;
    align-items:center;
    position:relative;
    display:flex;
}
#topic-punchcard {
    position:absolute;
    left:0;
    top:0;
    height:calc(100vh - 250px);
    width: 100%;
    z-index:1;
}
#topic-selector {
    position:absolute;
    right: 0;
    top:20%;
    max-height:50%;
    max-width:400px;
}
.graph-blocker {
    position:relative;
    left:0;
    top:0;
    height:100%;
    width:100%;
    background:#ffffff80;
    box-shadow:var(--box_shadow);
    font-weight:600;
    padding:1rem;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:2;
}
.graph-blocker-text {
    display:flex;   
}
.topic-label {
    max-height:1.4rem;
    max-width:400px;
    white-space:nowrap;
    overflow:hidden;
}
</style>