<template>
    <div class="float-end close-button close-button" @click="$emit('close')"><i class="fa fa-close"></i></div>
</template>

<script>
export default {
    name:'CloseButton'
}
</script>

<style>
.close-button {
    position: absolute;
    top: .75rem;
    right: 2rem;
    font-size:15px;
    font-weight:700;
    z-index:5;
    cursor:pointer;
}
.close-button:hover {
    color: var(--orange-highlight);
}
</style>